<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card
        class="auth-card"
        :loading="loading"
      >
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <v-img
            :src="require('@/assets/images/logos/logo.png')"
            max-height="30px"
            max-width="30px"
            alt="logo"
            contain
            class="me-3 "
          ></v-img>

          <h2 class="text-2xl font-weight-semibold">
            La Formation Pro
          </h2>
        </v-card-title>
        <div v-if="!error && !loading">
          <!-- title -->
          <v-card-text>
            <p class="text-2xl font-weight-semibold text--primary mb-2">
              Bienvenue {{ user.displayName }}! 👋🏻
            </p>
            <p class="mb-2">
              Il s'agit de votre première connexion, veuillez définir votre mot de passe !
            </p>
          </v-card-text>

          <!-- login form -->
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="password"
                outlined
                :rules="[v => !!v || 'Saisir un mot de passe']"
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Mot de passe"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
              <v-text-field
                v-model="passwordConfirm"
                outlined
                :rules="confirmPassRules"
                :type="isPasswordConfirmVisible ? 'text' : 'password'"
                label="Confirmer le mot de passe"
                :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
              ></v-text-field>

              <v-btn
                block
                color="primary"
                class="my-3"
                @click="setupAccount"
              >
                C'est parti
              </v-btn>
              <div class="d-flex align-end justify-end flex-wrap">
                <!-- Already registered -->
                <a
                  color="primary"
                  class="mt-1"
                  @click="$router.push({name:'login'})"
                >
                  Vous avez déjà un compte ?
                </a>
              </div>
            </v-form>
          </v-card-text>
        </div>
        <div v-else>
          <v-alert
            color="error"
            text
            class="mb-0"
          >
            <div class="d-flex align-start">
              <v-icon color="error">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{ alertText }}
                </p>
              </div>
            </div>
          </v-alert>
        </div>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAlertOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
  data() {
    return {
      user: {},
      error: false,
      loading: true,
      valid: true,
      password: '',
      passwordConfirm: '',
      alertText: '',
      isPasswordVisible: false,
      isPasswordConfirmVisible: false,
      confirmPassRules: [
        v => !!v || 'Confirmer le mot de passe',
        v => v === this.password || 'Les mots de passe doivent correspondre',
      ],
    }
  },
  mounted() {
    this.token = this.$route.query.token
    this.verifyToken()
  },
  setup() {
    return {
      icons: {
        mdiAlertOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },

  methods: {
    verifyToken() {
      this.loading = true
      this.$http.post(`${process.env.VUE_APP_API_URL}/users-permissions/first-login-infos/`, { firstLoginToken: this.token })
        .then(res => {
          if (res.status === 200) {
            this.user = res.data
          }
        })
        .catch(err => {
          if (err.response.data.statusCode === 404) {
            this.error = true
            this.alertText = 'Ce jeton n\'existe pas'
          } else {
            this.error = true
            this.alertText = 'Erreur lors de la vérification du jeton'
          }
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    setupAccount() {
      if (this.$refs.form.validate()) {
        this.$http.post(`${process.env.VUE_APP_API_URL}/users-permissions/first-login-setup/`,
          {
            firstLoginToken: this.token,
            password: this.password,
          })
          .then(res => {
            if (res.status === 200) {
              console.log(res.data)
              this.username = res.data.username
              this.login()
            }
          })
          .catch(err => {
            if (err.response.data.statusCode === 404) {
              this.alertText = 'Ce jeton n\'existe pas'
              this.error = true
            } else {
              this.alertText = 'Une erreur est survenue'
              this.error = true
            }
            console.log(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    login() {
      this.$store.dispatch('login', {
        identifier: this.username,
        password: this.password,
      }).then(() => this.$router.push('/'))
        .catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de l\'identification',
            value: true,
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
